import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CardInfo, CardInfoTwo }  from './CardInfo';
import LoremIpsum, { loremIpsum } from 'react-lorem-ipsum';
// import Ratio from 'react-bootstrap/Ratio';

import "./About.css";

function About() {
  const concreteItems = [
    "Diameter: From 1/2\" to 20\".",
    "Concrete.",
    "Reinforced Concrete.",
    "Asphalt."
  ]
  const wallSawing = [
    "Up to 29\" depth.",
    "We can wall saw windows, doorways, walls and HVAC ventilation.",
    "Wall sawing can be done in commerical or residential applications, what ever your need is.",
    "We can also flush cut walls and floors.",
    "We have portable hydraulic tools so we can service your needs on site or on the road anywhere you need cutting done."
  ]
  const slabItems = [
    "Our slab sawing can cut concrete slabs, curbs, expansion and control joints as well as flush cutting for curbs, floors and walls.",
    "We also cut asphalt small or big jobs."
  ]
  const removalItems = [
    "We provide removal and disposing of all concrete from the job site keeping the site clean."
  ]
  const concretePlacingAndPrepItems = [
    "We also provide concrete prep, backfilling, forming and placing.",
    "Floors, curbs, walls we do it all!"
  ]
  const cuttingItems = [
    "Slab sawing",
    "Expansion joints",
    "Trenches",
    "Applications: Roads, bridges, concrete slabs"
  ]
  const scanningItems = [
    "Transpointer - Precise transpointer to detect entry and exit points, as well as distance between them",
    'Ferroscan - Concrete detector for rebar localization, depth measurement and size estimation in structural analysis',
    'X-Scan - Efficent concrete scanner for structural analysis and to locate embedded objects in multiple layers'
  ]

  const group1 = ['Vancouver', 'Downtown Vancouver', 'North Van', 'West Van', 'Burnaby', 'Surrey', 'Delta', 'Richmond', 'Langley', 'Cloverdale'];
  const group2 = ['Coquitlam', 'Port Coquitlam', 'Port Moody', 'Maple Ridge', 'Abbottsford', 'Chillwack'];
  const group3 = ['White Rock', 'New Westminister', 'Tsawassen', 'Ladner']
  const group4 = ['Victoria', 'Nanaimo'];
  
  const placesWeService = [group1, group2, group3, group4];

  return (
    <div className="container-md section">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <h1>At MT Coring</h1>
            <p>We provide our clients with highly skilled technicians and state of the art coring equipment to service your needs quickly and efficently.</p>
            <p>We offer professional concrete coring and cutting service at a competitive price. Our diamond coring bits are produced with high quality steel to ensure a smooth professional core for all your plumbing, HVAC and electrical services.</p>
            <p>We also take site safety very serious, ensuring our work is completed safely for ourselves and all other trades on site. Our team also works closely with multiple scanning companies, so you can call us for scanning and coring services all in one.</p>
            <p>No more making numerous phone calls, call us for your one stop shop and let us make the calls for you.</p>
         </Col>
         <Col md={6}>
            <img src={require("assets/img/mikescoring/4bg.jpg")} alt={"construction site"}/>
         </Col>
        </Row>
      </Container>


      <Container style={{paddingTop: 50}}>
        <h2> Services</h2>
        <div className="services-container">
          <CardInfo
            title={"Coring"}
            items={concreteItems}
          />
          <CardInfo 
            title="Wall Sawing"
            items={wallSawing}
          />
          <CardInfo
            title="Slab Sawing"
            items={slabItems}
          />
          <CardInfo
            title="Removal & Disposal"
            items={removalItems}
          />
          <CardInfo
            title="Concrete Placing & Prep for Repour"
            items={concretePlacingAndPrepItems}
          />
          <CardInfo title={"Scanning provided through 3rd party trades"} items={scanningItems}/>

          {/*<CardInfo
            title={"Coring"}
            items={concreteItems}
          />
          <CardInfo
            title={"Cutting"}
            items={cuttingItems}
          />
          <CardInfo
            title={"Wall Cutting"}
            items={cuttingItems}
          />
          <CardInfo title={"Scanning provided through 3rd party trades"} items={scanningItems}/>*/}
        </div>
        {/*<Row>
          <h2> Services</h2>
          <Col md={12}>
            <Row>
              <Col>
                <CardInfo
                  title={"Coring"}
                  items={concreteItems}
                />
              </Col>
              <Col>
                <CardInfo
                  title={"Cutting"}
                  items={cuttingItems}
                />
              </Col>
            </Row>

            <Row>
              <Col>
              <CardInfo title={"Scanning provided through 3rd party trades"} items={scanningItems}/>
              </Col>
            </Row>
         </Col>
        </Row>*/}
      </Container>

       <Container style={{paddingTop: 50}}>
        <Row>
          <Col md={6}>
            <h1>Where we work</h1>
            <p>We have serviced sites all over the Lower Mainland and Greater Vancouver, including the island!</p>
            <p>Our areas include: </p>
            {
              placesWeService.map((group) => {

                return (
                  <ul> {
                    group.map((place) => {
                      return <li key={place}>{place}</li>
                    })
                  }
                  </ul>
                )
              })
            }
            <p>So don't worry, whether you're in Downtown Vancouver or in Nanaimo, give us a call for your coring and cutting needs</p>
         </Col>
         <Col md={6}>
          <img src={require("assets/img/mikescoring/2bg.jpg")} alt={"vancouver"}/>
         </Col>
        </Row>
        
      
      </Container>

    </div>
  )
}

export default About
